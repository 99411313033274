<i18n>
{
  "en": {
    "title": "Edit Pipeline",
    "input-1": "Pipeline name",
    "input-2": "Sum field",
    "input-3": "Lost reason",
    "input-4": "Define visible fields",
    "input-5": "Show card value",
    "input-6": "Show related products",
    "input-7": "Show related cards",
    "input-8": "Show related companies",
    "input-9": "Show related contacts",
    "input-10": "Usuário responsável por novo card",
    "edit": "Save updates"
  },
  "pt-BR": {
    "title": "Editar Pipeline",
    "input-1": "Nome do pipeline",
    "input-2": "Campo somatório",
    "input-3": "Motivo de abandono",
    "input-4": "Definir campos visíveis",
    "input-5": "Mostrar valor do card",
    "input-6": "Mostrar produtos relacionados",
    "input-7": "Mostrar cards relacionados",
    "input-8": "Mostrar empresas relacionadas",
    "input-9": "Mostrar contatos relacionados",
    "input-10": "Usuário responsável por novo card",
    "edit": "Salvar alterações"
  }
}
</i18n>

<template>
  <modal-action @close="closeEditPipeline(false)" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="edit" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeEditPipeline(false)" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form">
            <div class="fields">
              <we-input
                type="text"
                :label="$t('input-1')"
                v-model="pipeline.name"
                is-required
              />
              <we-input
                type="global-select"
                :label="$t('input-2')"
                v-model="pipeline.sum_field"
                :items="sumFieldItems"
                :clearable="false"
                is-required
              />
              <we-input
                type="global-select"
                :label="$t('input-10')"
                v-model="pipeline.set_card_owner_method"
                :items="setCardOwnerMethod"
                :clearable="false"
                is-required
              />
              <we-input
                type="tags"
                :label="$t('input-3')"
                v-model="pipeline.lost_reason_options"
                :items="pipeline.lost_reason_options"
                :clearable="false"
                is-required
              />
              <v-switch
                v-model="advancedConfigs"
                :label="$t('input-4')"
                hide-details
              />
              <v-switch
                v-if="advancedConfigs"
                v-model="pipeline.schema.show_deal_amount"
                :label="$t('input-5')"
                hide-details
              />
              <v-switch
                v-if="advancedConfigs"
                v-model="pipeline.schema.show_deal_products"
                :label="$t('input-6')"
                hide-details
              />
              <v-switch
                v-if="advancedConfigs"
                v-model="pipeline.schema.show_deal_deals"
                :label="$t('input-7')"
                hide-details
              />
              <v-switch
                v-if="advancedConfigs"
                v-model="pipeline.schema.show_deal_companies"
                :label="$t('input-8')"
                hide-details
              />
              <v-switch
                v-if="advancedConfigs"
                v-model="pipeline.schema.show_deal_contacts"
                :label="$t('input-9')"
                hide-details
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('edit')"
            icon="check"
            :loading="btnLoading"
            @click="editPipeline"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      advancedConfigs: false,
      btnLoading: false,
      loading: true
    };
  },
  computed: {
    pipeline() {
      return this.$store.getters.getPipeline;
    },
    customFields() {
      return this.$store.getters.getCustomFields || [];
    },
    customFieldsList() {
      return this.customFields
        ?.filter(e => e.entity === "deal")
        .map(e => {
          return {
            text: e.label,
            value: e.name
          };
        });
    },
    sumFieldItems() {
      return [
        {
          text: "Valor",
          value: "amount"
        },
        {
          text: "Produtos",
          value: "products"
        },
        ...this.customFieldsList
      ];
    },
    setCardOwnerMethod() {
      return [
        {
          text: "Não atribuir",
          value: "none"
        },
        {
          text: "Atribuir automaticamente",
          value: "auto"
        },
      ];
    }
  },
  methods: {
    ...mapActions([
      "editPipelineRequest",
      "pipelineRequest",
      "customFieldsRequest"
    ]),
    closeEditPipeline(reload) {
      this.$router.push({
        name: "pipelinesConfigs",
        params: { dealId: Number() },
        query: { reload }
      });
    },
    async editPipeline() {
      this.btnLoading = true;
      await this.editPipelineRequest(this.pipeline);
      this.closeEditPipeline(true);
      this.btnLoading = false;
    }
  },
  async mounted() {
    await this.pipelineRequest(this.$route.params.pipelineId);
    await this.customFieldsRequest({
      entity: "deal",
      type: "number,money"
    });
    this.pipeline.sum_field = this.pipeline.sum_field.name;
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
#action_modal.columns-1 .view .modal {
  width: 400px;
}
</style>
