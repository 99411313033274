w<i18n>
{
  "en": {
    "search": "Search",
    "title": "Pipeline wizard 🧙‍♂️",
    "stages": "Pipeline stages",
    "all": "All",
    "sales": "Sales",
    "aftersales": "After Sales",
    "hr": "HR",
    "financial": "Financial",
    "legal": "Legal",
    "marketing": "Marketing",
    "projects": "Projects",
    "technology": "Technology",
    "facilities": "Facilities",
    "lost_reason": "Abandon",
    "create": "Use template",
    "create-mobile": "Create",
    "benefit-1-title": "Ready to use",
    "benefit-1-description": "Standardize and manage your operation's work in minutes",
    "benefit-2-title": "No/low-code",
    "benefit-2-description": "Customize and create automations without a tech team",
    "benefit-3-title": "Multiple Integrations",
    "benefit-3-description": "Integrate with your ERP, HR systems or other systems"
  },
  "pt-BR": {
    "search": "Pesquisar",
    "title": "Pipeline wizard 🧙‍♂️",
    "stages": "Estágios do pipeline",
    "all": "Tudo",
    "sales": "Vendas",
    "aftersales": "Pós Venda",
    "hr": "RH",
    "financial": "Financeiro",
    "legal": "Jurídico",
    "marketing": "Marketing",
    "projects": "Projetos",
    "technology": "Tecnologia",
    "facilities": "Facilities",
    "lost_reason": "Desistência",
    "create": "Usar template",
    "create-mobile": "Criar",
    "benefit-1-title": "Pronto para usar",
    "benefit-1-description": "Padronize e gerencie o trabalho da sua operação em minutos",
    "benefit-2-title": "No/low-code",
    "benefit-2-description": "Customize e crie automações sem precisar de um time de tecnologia",
    "benefit-3-title": "Múltiplas integrações",
    "benefit-3-description": "Integre com seu ERP, sistemas de RH ou outros sistemas que sua empresa usa"
  }
}
</i18n>

<template>
  <modal-action class="create-pipeline" @close="closeIfIsCreating">
    <template #view>
      <div class="modal" id="create-pipeline">
        <div v-if="!selectedTemplate" class="modal-body create-pipeline">
          <div class="create-pipeline-header">
            <div class="header-top">
              <h1 class="we-title">
                {{ $t("title") }}
              </h1>
              <we-input
                type="search"
                :inputLabel="$t('search')"
                v-model="search"
                @input="resetNavigation"
              />
              <we-icon-button
                v-if="!isOnboarding"
                @click="closeModal"
                icon="times"
              />
            </div>
            <we-nav-x
              v-if="navigationItems && navigationItems.length > 0"
              :items="navigationItems"
              :activeIndex="currentNavIndex"
              @select="changeGroup"
            />
          </div>
          <div class="create-pipeline-content">
            <div class="template-grid">
              <we-card-icon
                class="button"
                v-for="template in filteredTemplates"
                :key="template.id"
                @click="selectTemplate(template)"
                cy="select-template"
                :color="colors[template.group]"
                :icon="getTemplateIcon(template)"
                :label="$t(template.group)"
                :title="template.name"
              >
                <template #sublabel>
                  {{ template.description }}
                </template>
              </we-card-icon>
            </div>
          </div>
        </div>
        <div v-else class="modal-body selected-template">
          <div class="selected-template-header">
            <we-icon-button
              class="big-mobile"
              icon="chevron-left"
              @click="selectedTemplate = null"
              :loading="createButtonLoading"
            />
            <div class="we-title">{{ selectedTemplate.name }}</div>
            <we-button
              class="primary mobile-icon"
              :text="$t('create')"
              :mobile-text="$t('create-mobile')"
              icon="plus"
              cy="create-pipeline"
              @click="createPipeline"
            />
          </div>
          <div class="selected-template-content">
            <h3 class="first-title">{{ $t("stages") }}</h3>
            <div class="template-stages">
              <div
                class="stage"
                v-for="(stage, i) in selectedTemplate.schema"
                :key="stage"
              >
                <div class="stage-number">
                  {{ i + 1 }}
                </div>
                <div class="stage-name">
                  {{ stage }}
                </div>
              </div>
            </div>
            <vue-markdown>{{ selectedTemplate.content }}</vue-markdown>
            <div class="template-benefits">
              <we-card-icon
                v-for="benefit in benefits"
                :key="benefit.title"
                color="primary"
                :icon="benefit.icon"
                :label="benefit.title"
                :title="benefit.description"
              />
            </div>
          </div>
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      currentNavIndex: 0,
      selectedGroup: "all",
      selectedTemplate: null,
      loading: true,
      search: "",
      createButtonLoading: false
    };
  },
  computed: {
    colors() {
      return {
        all: "info",
        start: "black",
        sales: "orange",
        aftersales: "red",
        hr: "purple",
        financial: "green",
        legal: "blue",
        marketing: "lightBlue",
        projects: "yellow",
        technology: "primary",
        facilities: "info"
      };
    },
    isOnboarding() {
      return this.$route.name === "createPipelineOnboarding";
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    },
    filteredTemplates() {
      return this.templates.filter(e => {
        if (this.search.length > 0) {
          return e.name.toLowerCase().includes(this.search.toLowerCase());
        }
        if (this.selectedGroup === "all") return e;

        return e.group === this.selectedGroup;
      });
    },
    templates() {
      return this.$store.getters.getTemplates;
    },
    benefits() {
      return [
        {
          icon: "flag-checkered",
          title: this.$t("benefit-1-title"),
          description: this.$t("benefit-1-description")
        },
        {
          icon: "code",
          title: this.$t("benefit-2-title"),
          description: this.$t("benefit-2-description")
        },
        {
          icon: "code-branch",
          title: this.$t("benefit-3-title"),
          description: this.$t("benefit-3-description")
        }
      ];
    },
    navigationItems() {
      return [
        {
          id: 0,
          name: this.$t("all"),
          icon: "star",
          department: "all"
        },
        {
          id: 1,
          name: this.$t("sales"),
          icon: "filter-circle-dollar",
          department: "sales"
        },
        {
          id: 2,
          name: this.$t("aftersales"),
          icon: "headset",
          department: "aftersales"
        },
        {
          id: 3,
          name: this.$t("hr"),
          icon: "id-card-clip",
          department: "hr"
        },
        {
          id: 4,
          name: this.$t("financial"),
          icon: "file-invoice-dollar",
          department: "financial"
        },
        {
          id: 5,
          name: this.$t("legal"),
          icon: "user-tie",
          department: "legal"
        },
        {
          id: 6,
          name: this.$t("marketing"),
          icon: "bullhorn",
          department: "marketing"
        },
        {
          id: 7,
          name: this.$t("projects"),
          icon: "chalkboard-user",
          department: "projects"
        },
        {
          id: 8,
          name: this.$t("technology"),
          icon: "desktop",
          department: "technology"
        },
        {
          id: 9,
          name: this.$t("facilities"),
          icon: "people-carry-box",
          department: "facilities"
        }
      ];
    }
  },
  methods: {
    ...mapActions(["createPipelineRequest"]),
    getTemplateIcon(template) {
      const currentItem = this.navigationItems.find(
        e => template.group === e.department
      );

      if (!currentItem?.icon) return "star";

      return currentItem.icon;
    },
    closeIfIsCreating() {
      if (this.isOnboarding) return;

      this.closeModal();
    },
    closeModal() {
      if (this.isOnboarding) {
        this.$router.push({ name: "home", query: { fullReload: true } });
      } else {
        this.$router.push({
          name: "pipelinesConfigs",
          query: { reload: true }
        });
      }
    },
    resetNavigation() {
      this.changeGroup(0);
    },
    changeGroup(event) {
      this.currentNavIndex = event;
      this.selectedGroup = this.navigationItems[event].department;
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
    },
    async createPipeline() {
      this.createButtonLoading = true;
      const payload = {
        name: this.selectedTemplate.name,
        stages: this.selectedTemplate.schema,
        lost_reason_options: [this.$t("lost_reason")],
        schema: {
          show_deal_amount: true,
          show_deal_companies: true,
          show_deal_contacts: true,
          show_deal_deals: true,
          show_deal_products: true
        },
        addon: this.selectedTemplate.addon
      };

      await this.createPipelineRequest(payload);
      this.createButtonLoading = false;

      this.closeModal();
    }
  },
  async mounted() {
    // if (this.templates.length <= 0) {
    //   await this.templatesRequest();
    // }

    this.loading = false;
  }
};
</script>

<style lang="scss">
#action_modal.create-pipeline {
  z-index: 8;
  .modal#create-pipeline {
    max-width: 900px;
    height: 100%;
    max-height: 790px;

    @include mobile {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .modal-body {
      padding: 0;
      height: 100%;
      display: flex;
      flex-flow: column;
      .create-pipeline-header {
        border-bottom: 1px solid var(--line);

        .header-top {
          position: relative;
          padding: 0 40px;
          padding-top: 14.5px;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          @include mobile {
            padding: 15px 15px 0 15px;
            display: block;
          }

          .we-title {
            font-size: 24px;
            font-weight: $semibold;
            margin-right: 16px;

            @include mobile {
              margin-bottom: 16px;
            }
          }

          .we-icon-button {
            position: absolute;
            top: 14.5px;
            right: 15px;
          }
        }

        .we-nav-x {
          margin-left: 40px;
          max-width: 100%;

          @include mobile {
            margin-left: 0;
          }
        }
      }

      .create-pipeline-content {
        padding: 40px;
        height: 100%;
        flex: auto;
        overflow-y: auto;

        @include mobile {
          padding: 15px;
        }
        .template-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          gap: 14px;

          @include mobile {
            gap: 15px;
          }
        }
      }

      .selected-template-header {
        display: grid;
        align-items: center;
        grid-template-columns: 40px 1fr 180px;
        gap: 16px;
        border-bottom: 1px solid var(--line);
        width: 100%;
        padding: 14px 40px;
        position: relative;

        @include mobile {
          grid-template-columns: 50px 1fr 50px;
          height: 50px;
          padding: 0;
        }

        .we-icon-button {
          margin: 0;
        }

        .we-title {
          font-size: 24px;
          font-weight: $semibold;

          @include mobile {
            font-size: 16px;
            text-align: center;
          }
        }
      }

      .selected-template-content {
        padding: 16px 40px;
        padding-bottom: 120px;
        flex: auto;
        overflow-y: auto;

        @include mobile {
          padding: 15px 15px 40px 15px;
        }

        .template-benefits {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
          gap: 16px;
          margin-top: 2.5rem;

          @include mobile {
            grid-template-columns: 1fr;
          }
        }

        .template-stages {
          display: flex;
          flex-flow: row;
          align-items: center;
          flex-wrap: wrap;

          .stage {
            height: 38px;
            display: grid;
            grid-template-columns: 38px 1fr;
            align-items: center;
            border-radius: 5px;
            border: 1px solid var(--primary);
            margin-right: 12px;
            margin-bottom: 12px;
            overflow: hidden;

            &:nth-last-child(2) {
              border: 1px solid var(--green);

              .stage-number {
                background: rgba(var(--green-rgb), 0.1);
                color: var(--green);
                border-color: var(--green);
              }
            }

            &:last-child {
              margin-right: 0;
              border: 1px solid var(--action-hover);

              .stage-number {
                background: var(--action);
                color: var(--text-1);
                border-color: var(--action-hover);
              }
            }

            .stage-number {
              font-weight: $medium;
              color: var(--primary);
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid var(--primary);
              background: var(--primary-bg);
            }

            .stage-name {
              font-size: 14px;
              font-weight: $medium;
              color: var(--text-1);
              padding: 0 12px;
            }
          }
        }

        $default-margin: 1.25rem auto;
        $default-font-size: 1rem;

        hr {
          margin-top: -0.875rem;
          border-top: 1px solid var(--text-3);
        }

        figcaption {
          font-size: 0.8125rem;
          text-align: center;
          margin-top: -1rem;
        }

        ul {
          list-style: initial;
          color: var(--text-1);
          font-size: $default-font-size;
          margin: $default-margin;
        }

        ol {
          list-style: decimal;
          color: var(--text-1);
          font-size: $default-font-size;
          margin: $default-margin;
        }

        code {
          padding: 0;
          font-size: 100%;
        }

        blockquote {
          padding: 0.1px 16px;
          border-left: 5px solid var(--primary);
          margin: $default-margin;
          background: var(--background-1);

          p {
            margin: 1rem 0;
          }
        }

        p {
          color: var(--text-1);
          font-size: $default-font-size;
          margin: $default-margin;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 2.5rem;
          margin-bottom: 1.25rem;
          font-weight: $semibold;
          color: var(--text-1);
          line-height: initial;
        }

        a {
          font-weight: $semibold;
          word-break: break-word;
        }

        h1 {
          font-size: 1.75rem;
        }

        h2 {
          font-size: 1.5rem;
        }

        h3 {
          font-size: 1.25rem;
        }

        h4 {
          font-size: 1.25rem;
        }

        h5 {
          font-size: 1.25rem;
        }

        h6 {
          font-size: $default-font-size;
        }

        picture {
          display: block;
          width: 100%;
          margin: 60px 0;

          img {
            width: 100%;
            height: auto;
            border-radius: 7px;
          }
        }

        .first-title {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
