<i18n>
{
  "en": {
    "title-create": "New Stage",
    "title-update": "Update Stage",
    "input-1": "Stage name",
    "input-2": "Stage timeout (hours)",
    "input-3": "Show all custom fields",
    "input-4": "Custom fields",
    "input-5": "WhatsApp message",
    "input-6": "Initial stage",
    "input-7": "Won stage",
    "create": "Create stage",
    "update": "Update stage"
  },
  "pt-BR": {
    "title-create": "Novo Estágio",
    "title-update": "Editar Estágio",
    "input-1": "Nome do estágio",
    "input-2": "Tempo limite no estágio (em horas)",
    "input-3": "Mostrar todos os campos customizados",
    "input-4": "Campos customizados",
    "input-5": "Mensagem para WhatsApp",
    "input-6": "Estágio inicial",
    "input-7": "Estágio de conversão",
    "create": "Criar estágio",
    "update": "Salvar estágio"
  }
}
</i18n>

<template>
  <modal-action @close="closeModal(false)" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon :icon="stageExist ? 'edit' : 'plus'" />
            </div>
            {{ stageExist ? $t("title-update") : $t("title-create") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeModal(false)" />
          </div>
        </div>
        <div class="modal-body">
          <div class="fields">
            <we-input
              type="text"
              :label="$t('input-1')"
              v-model="stage.name"
              cy="create-stage-name"
            />
            <we-input
              type="number"
              :label="$t('input-2')"
              v-model="stage.timeout"
            />
            <v-switch
              v-model="showAllCustomFields"
              :label="$t('input-3')"
              @change="changeShowAllCustomFields"
              hide-details
            />
            <we-input
              v-if="!showAllCustomFields"
              type="custom-fields"
              v-model="stage.custom_fields"
              :items="dealCustomFields"
              :clearable="false"
              :label="$t('input-4')"
            />
            <we-input
              :label="$t('input-5')"
              v-model="stage.whatsapp_default_message"
              type="textarea"
              rows="1"
            />
          </div>
          <div class="checkbox">
            <v-switch
              v-model="stage.is_the_first_stage"
              :label="$t('input-6')"
              hide-details
            />
            <v-switch
              v-model="stage.is_the_win_stage"
              :label="$t('input-7')"
              hide-details
            />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="stageExist ? $t('update') : $t('create')"
            :icon="stageExist ? 'check' : 'plus'"
            :loading="btnLoading"
            @click="stageAction"
            cy="create-stage"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      loading: true,
      showAllCustomFields: true,
      stage: {
        name: "",
        is_the_first_stage: false,
        is_the_win_stage: false,
        timeout: 24,
        whatsapp_default_message: "",
        custom_fields: []
      }
    };
  },
  computed: {
    stageExist() {
      return this.$route.params.stageId;
    },
    dealCustomFields() {
      return this.$store.getters.getDealCustomFieldsInputs;
    }
  },
  methods: {
    ...mapActions([
      "editStageRequest",
      "createStageRequest",
      "stageRequest",
      "customFieldsRequest"
    ]),
    stageAction() {
      this.stageExist ? this.editStage() : this.createStage();
    },
    closeModal(reload) {
      this.$router.push({
        name: "pipelinesConfigs",
        query: { reload }
      });
    },
    changeShowAllCustomFields(val) {
      this.stage.custom_fields = val ? null : [];
    },
    async editStage() {
      this.btnLoading = true;
      this.stage.pipelineId = this.$route.params.pipelineId;
      this.stage.id = this.$route.params.stageId;
      if (this.showAllCustomFields) {
        this.stage.custom_fields = null;
      }
      await this.editStageRequest(this.stage);
      this.closeModal(true);
      this.btnLoading = false;
    },
    async createStage() {
      this.btnLoading = true;
      this.stage.pipelineId = this.$route.params.pipelineId;
      if (this.showAllCustomFields) {
        this.stage.custom_fields = null;
      }
      await this.createStageRequest(this.stage);
      this.closeModal(true);
      this.btnLoading = false;
    }
  },
  async mounted() {
    if (this.stageExist) {
      const payload = {
        pipelineId: this.$route.params.pipelineId,
        id: this.$route.params.stageId
      };
      await this.stageRequest(payload);

      this.stage = { ...this.$store.getters.getStage };

      if (this.stage.custom_fields) {
        this.showAllCustomFields = false;
      }
    }

    await this.customFieldsRequest({ entity: "deal" });
    this.loading = false;
  }
};
</script>

<style scoped lang="scss">
#action_modal.columns-1 .view .modal {
  width: 414px;
}
</style>
