<i18n>
{
  "en": {
    "not-found": "Workflow not found",
    "not-found-description-1": "To create an external form you need to configure a workflow,",
    "not-found-description-2": "click here to create",
    "title": "New Form",
    "input-1": "Domain",
    "input-2": "Stages to save the card",
    "create": "Create form"
  },
  "pt-BR": {
    "not-found": "Nenhum pipeline encontrado",
    "not-found-description-1": "Para criar um formulário web você precisa configurar um pipeline,",
    "not-found-description-2": "clique aqui para criar",
    "title": "Novo Formulário",
    "input-1": "Domínio",
    "input-2": "Estágios para salvar a requisição",
    "create": "Criar formulário"
  }
}
</i18n>

<template>
  <modal-action @close="closeCreateWebform" :columns="1">
    <template #view>
      <v-form
        class="modal"
        :class="{ missing: pipelines.length === 0 }"
        ref="form"
        @submit.prevent="createWebform"
      >
        <we-not-found v-if="pipelines.length === 0">
          <template #title> {{ $t("not-found") }} </template>
          <template #description>
            {{ $t("not-found-description-1") }}
            <router-link
              :to="{ name: 'pipelinesConfigs' }"
              class="green--text"
              active-class="active"
            >
              {{ $t("not-found-description-2") }}
            </router-link>
          </template>
        </we-not-found>
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="plus" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCreateWebform" />
          </div>
        </div>
        <div class="modal-body">
          <div class="fields" v-if="pipelines.length > 0">
            <we-input
              type="text"
              :label="$t('input-1')"
              mask="aaaaaaaaaaaaaaa"
              v-model="webform.domain"
              is-required
              cy="create-web-form-domain"
            />
            <we-input
              type="global-multiselect"
              v-model="webform.initial_pipeline_stages"
              :items="allStagesWithPipelinesList"
              :label="$t('input-2')"
              is-required
              cy="create-web-form-stage"
            />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('create')"
            icon="plus"
            :loading="btnLoading"
            :disabled="pipelines.length === 0"
            cy="create-web-form"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </v-form>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      btnLoading: false,
      webform: {}
    };
  },
  computed: {
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    allStagesWithPipelinesList() {
      let items = [];

      this.pipelines.forEach(pipeline => {
        pipeline?.stages.forEach(stage => {
          items.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id
          });
        });
      });

      return items;
    }
  },
  methods: {
    ...mapActions(["createWebformRequest", "pipelinesRequest"]),
    closeCreateWebform() {
      this.$router.push({
        name: "webformsConfigs"
      });
    },
    async createWebform() {
      const validated = this.$refs.form.validate();

      if (validated) {
        this.btnLoading = true;
        this.webform.is_lead_mode_enabled = true;
        const response = await this.createWebformRequest(this.webform);
        this.$router.push({
          name: "editWebform",
          params: { id: response.id }
        });
        this.btnLoading = false;
      }
    },
    async doRequests() {
      this.loading = true;
      await this.pipelinesRequest();
      this.loading = false;
    }
  },
  mounted() {
    this.doRequests();
  }
};
</script>

<style lang="scss" scoped>
.modal.missing {
  .modal-body {
    min-height: 250px;
  }
}

.we-not-found {
  padding-top: 75px;
}
</style>
