<i18n>
{
  "en": {
    "title": "Pipelines",
    "initial-stage": "Initial stage: reports will be generated from here",
    "win-stage": "Won stage: reports will be generated up to here",
    "create-pipeline": "New pipeline",
    "not-found": "You didn't create any",
    "not-found-click": "Click on the button",
    "not-found-new-pipeline": "New Pipeline",
    "not-found-to-create": "to create",
    "stage-options": "Stage options",
    "duplicate-pipeline": "Duplicate pipeline",
    "edit-pipeline": "Edit pipeline",
    "edit-stage": "Edit stage",
    "delete-stage": "Delete stage",
    "delete-stage-description": "By deleting the stage, all opportunities related to it will be permanently deleted.",
    "delete-pipeline": "Delete pipeline",
    "delete-pipeline-description": "By deleting the pipeline, all stages and opportunities related to it will be permanently deleted.",
    "duplicate-pipeline-description": "By duplicating the pipeline, all stages will keep the same but the cards won't be copied.",
    "lost": "Lost",
    "create-stage": "New stage",
    "copy": "Copy"
  },
  "pt-BR": {
    "title": "Pipelines",
    "initial-stage": "Estágio inicial: os relatórios serão gerados a partir daqui",
    "win-stage": "Estágio de conversão: os relatórios serão gerados até aqui",
    "create-pipeline": "Novo pipeline",
    "not-found": "Você não criou nenhum",
    "not-found-click": "Clique no botão",
    "not-found-new-pipeline": "Novo Pipeline",
    "not-found-to-create": "para criar",
    "stage-options": "Opções do estágio",
    "duplicate-pipeline": "Duplicar pipeline",
    "edit-pipeline": "Editar pipeline",
    "edit-stage": "Editar estágio",
    "delete-stage": "Deletar estágio",
    "delete-stage-description": "Ao remover o estágio todas os cards relacionadas a ele serão deletadas permanentemente em conformidade com a LGPD.",
    "delete-pipeline": "Deletar pipeline",
    "delete-pipeline-description": "Ao deletar o pipeline todos os estágios e cards relacionados a ele serão deletados permanentemente conforme as regras da LGPD.",
    "duplicate-pipeline-description": "Ao duplicar um pipeline todos os estágios se manterão mas os cards não serão duplicadas.",
    "lost": "Abandonado",
    "create-stage": "Novo estágio",
    "copy": "Cópia"
  }
}
</i18n>

<template>
  <div id="pipelines-configs">
    <nav-top returnPage="configurations" :title="$t('title')">
      <template #top-content>
        <we-button
          class="success"
          :text="$t('create-pipeline')"
          icon="plus"
          @click="$router.push({ name: 'createPipeline' })"
          cy="create-pipeline-cta"
        />
        <router-view />
      </template>
    </nav-top>
    <div class="board">
      <div class="pipelines-grid">
        <div class="pipelines-list">
          <div class="list-name">
            Pipelines
          </div>
          <container
            class="list-group"
            :animation-duration="200"
            @drop="e => movePipeline(e)"
            :get-child-payload="getPipelinePayload()"
            drag-class="dragged"
            :scrollSensitivity="200"
          >
            <draggable
              class="list-group"
              v-for="(pipeline, i) in pipelinesDrag"
              :key="pipeline.id"
            >
              <div
                class="item"
                :class="{ active: i === pipelineSelected }"
                @click="selectPipeline(i)"
              >
                {{ pipeline.name }}
              </div>
            </draggable>
          </container>
        </div>
        <div class="pipeline-selected">
          <we-not-found v-if="!hasPipelines">
            <template #title> {{ $t("not-found") }} <b>pipeline</b> </template>
            <template #description>
              {{ $t("not-found-click") }}
              <b class="green--text">+ {{ $t("not-found-new-pipeline") }}</b>
              {{ $t("not-found-to-create") }}
            </template>
          </we-not-found>
          <div class="content" v-if="hasPipelines">
            <div class="head">
              <we-text-tooltip :text="pipelineName" class="name" />
              <div class="actions">
                <confirm-modal
                  type="confirm"
                  :open="confirmDuplicateOpened"
                  @close="confirmDuplicateOpened = false"
                  @confirm="duplicatePipeline()"
                  :description="$t('duplicate-pipeline-description')"
                />
                <we-icon-button
                  :icon="['far', 'copy']"
                  :name="$t('duplicate-pipeline')"
                  @click="confirmDuplicateOpened = true"
                />
                <we-icon-button
                  icon="edit"
                  :name="$t('edit-pipeline')"
                  @click="
                    $router.push({
                      name: 'editPipeline',
                      params: { pipelineId: pipelineId }
                    })
                  "
                />
                <confirm-modal
                  :open="confirmDeleteOpened"
                  @close="confirmDeleteOpened = false"
                  @confirm="deletePipeline()"
                  :description="$t('delete-pipeline-description')"
                />
                <confirm-modal
                  :open="confirmDeleteOpenedStage"
                  @close="confirmDeleteOpenedStage = false"
                  @confirm="deleteStage()"
                  :description="$t('delete-stage-description')"
                />
                <we-icon-button
                  :icon="['far', 'trash-alt']"
                  :name="$t('delete-pipeline')"
                  direction="bottom"
                  @click="
                    confirmDeleteOpened = true;
                    selectedPipelineId = pipelineId;
                  "
                  cy="delete-pipeline"
                />
              </div>
            </div>
            <div class="stages-list">
              <container
                class="list-group"
                :animation-duration="200"
                @drop="e => moveStage(e)"
                :get-child-payload="getStagePayload()"
                drag-class="dragged"
                :scrollSensitivity="200"
              >
                <draggable v-for="stage in stages" :key="stage.id">
                  <div class="item">
                    <div class="name">
                      {{ stage.name }}
                      <v-tooltip
                        top
                        v-if="stage.is_the_first_stage"
                        :disabled="!isDesktop"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <font-awesome-icon
                            v-bind="attrs"
                            v-on="on"
                            icon="flag-checkered"
                          />
                        </template>
                        <span>{{ $t("initial-stage") }}</span>
                      </v-tooltip>
                      <v-tooltip
                        top
                        v-if="stage.is_the_win_stage"
                        :disabled="!isDesktop"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <font-awesome-icon
                            v-bind="attrs"
                            v-on="on"
                            icon="award"
                          />
                        </template>
                        <span>{{ $t("win-stage") }}</span>
                      </v-tooltip>
                    </div>
                    <div class="actions">
                      <we-balloon>
                        <template #action>
                          <we-icon-button
                            icon="ellipsis-h"
                            :name="$t('stage-options')"
                            cy="configure-stage"
                          />
                        </template>
                        <template #balloon>
                          <div
                            class="balloon-item"
                            @click="
                              $router.push({
                                name: 'editStage',
                                params: {
                                  pipelineId: pipelineId,
                                  stageId: stage.id
                                }
                              })
                            "
                          >
                            <font-awesome-icon icon="edit" />
                            {{ $t("edit-stage") }}
                          </div>
                          <div
                            class="balloon-item"
                            @click="
                              confirmDeleteOpenedStage = true;
                              confirmDeleteStageId = stage.id;
                            "
                            cy="delete-stage"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                            />
                            {{ $t("delete-stage") }}
                          </div>
                        </template>
                      </we-balloon>
                    </div>
                  </div>
                </draggable>
                <div class="item disabled">
                  <div class="name">{{ $t("lost") }}</div>
                </div>
              </container>
            </div>

            <div class="stages-list">
              <we-button
                class="primary fill"
                :text="$t('create-stage')"
                icon="plus"
                @click="
                  $router.push({
                    name: 'createStage',
                    params: { pipelineId: pipelineId }
                  })
                "
                cy="create-stage-cta"
              />
            </div>
          </div>
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      confirmDuplicateOpened: false,
      confirmDeleteOpened: false,
      confirmDeleteOpenedStage: false,
      loadingBtn: false,
      loading: true,
      pipelineSelected: 0,
      pipelinesDrag: [],
      stages: [],
      confirmDeleteStageId: 0,
      selectedPipelineId: 0
    };
  },
  computed: {
    pipelineId() {
      return this.pipeline?.id;
    },
    pipelineName() {
      return this.pipeline?.name;
    },
    pipelines() {
      return this.$store.getters.getPipelinesWithoutLost;
    },
    pipeline() {
      return this.pipelinesDrag[this.pipelineSelected];
    },
    hasPipelines() {
      return this.pipelines.length > 0;
    }
  },
  methods: {
    ...mapActions([
      "pipelinesRequest",
      "deletePipelineRequest",
      "deleteStageRequest",
      "editStageRequest",
      "editPipelineRequest",
      "createPipelineRequest"
    ]),
    async duplicatePipeline() {
      if (!this.pipeline?.stages) return;

      this.loading = true;

      const copyStages = [...this.pipeline.stages.map(e => e.name)];

      copyStages.push("Abandonado");

      const copyPipeline = {
        lost_reason_options: this.pipeline.lost_reason_options,
        name: `${this.$t("copy")} - ${this.pipeline.name}`,
        schema: this.pipeline.schema,
        stages: copyStages
      };

      await this.createPipelineRequest(copyPipeline);
      this.doRequests();
    },
    getPipelinePayload() {
      return index => {
        return this.pipelines[index];
      };
    },
    getStagePayload() {
      return index => {
        return this.stages[index];
      };
    },
    async doRequests() {
      await this.pipelinesRequest();
      if (this.pipelines.length > 0) {
        this.pipelinesDrag = this.pipelines;
        this.stages = [...this.pipeline?.stages];
      } else {
        this.pipelinesDrag = [];
        this.stages = [];
      }

      this.loading = false;
    },
    selectPipeline(index) {
      this.pipelineSelected = index;
      this.stages = [...this.pipeline?.stages];
    },
    async deletePipeline() {
      this.loading = true;
      await this.deletePipelineRequest(this.selectedPipelineId);
      this.pipelineSelected = 0;
      await this.doRequests();
    },
    async deleteStage() {
      this.loading = true;
      const payload = {
        id: this.confirmDeleteStageId,
        pipelineId: this.pipelineId
      };
      await this.deleteStageRequest(payload);
      await this.doRequests();
    },
    async movePipeline(evt) {
      if (this.pipelineSelected === evt.removedIndex) {
        this.pipelineSelected = evt.addedIndex;
      }

      let pipeline = evt.payload;
      pipeline.indexes = [];

      this.pipelinesDrag.splice(evt.removedIndex, 1);
      this.pipelinesDrag.splice(evt.addedIndex, 0, evt.payload);
      pipeline.indexes = this.pipelinesDrag.map(e => e.id);

      await this.editPipelineRequest(pipeline);
      await this.doRequests();
    },
    async moveStage(evt) {
      let payloadObject = {};
      this.stages.splice(evt.removedIndex, 1);
      this.stages.splice(evt.addedIndex, 0, evt.payload);
      payloadObject[this.pipeline.id] = this.stages.map(stage => stage.id);

      const payload = {
        id: evt.payload.id,
        pipelineId: evt.payload.pipeline_id,
        name: evt.payload.name,
        indexes: payloadObject[this.pipelineId]
      };

      await this.editStageRequest(payload);
      await this.doRequests();
    }
  },
  mounted() {
    this.doRequests();
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name.includes("create") || from.name.includes("edit")) {
      if (to.query.reload) {
        this.loading = true;
        this.doRequests();
      }
    }
    next();
  }
};
</script>

<style lang="scss">
#pipelines-configs {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;

  .board {
    background: var(--background-2);
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .pipelines-grid {
    min-height: 500px;
    max-width: 800px;
    height: fit-content;
    margin: 0 auto;
    padding: 69px 50px;
    display: grid;
    grid-template-columns: 227px $column-size;
    gap: 18px;
  }

  .pipelines-list {
    border-right: 1px solid var(--line);
    padding-right: 18px;

    .list-name {
      font-size: 14px;
      font-weight: $medium;
      text-transform: uppercase;
      color: var(--text-3);
      padding-left: 18px;
      margin-bottom: 8px;
      letter-spacing: 1px;
    }

    .item {
      overflow-wrap: anywhere;
      height: fit-content;
      display: flex;
      align-items: center;
      padding: 9.5px 18px;
      font-size: 14px;
      font-weight: $medium;
      color: var(--text-1);
      background: var(--action);
      border-radius: 7px;
      cursor: grab;
      margin-bottom: 8px;

      &:hover {
        background: var(--action-hover);
      }

      &.active {
        color: var(--primary);
        background: var(--primary-bg);
      }
    }

    .we-button {
      margin-bottom: 60px;
    }
  }

  .pipeline-selected {
    position: relative;
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .name {
        font-size: 24px;
        font-weight: $regular;
        color: var(--text-1);
      }

      .actions {
        display: flex;
        align-items: center;

        button {
          margin-left: 4px;
        }
      }
    }

    .stages-list {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 12px;
        padding-right: 4px;
        margin-bottom: 12px;
        border-radius: 10px;
        background: var(--background-1);
        border: 1px solid var(--line);
        cursor: grab;

        &:hover {
          border: 1px solid var(--primary);
        }

        &.disabled {
          background: var(--background-1-hover);
          cursor: not-allowed;
          border: 1px solid var(--line);
          min-height: 50px;
        }

        .name {
          font-size: 16px;
          font-weight: $medium;
          color: var(--text-1);
          display: flex;
          align-items: center;

          svg {
            color: var(--text-2);
            height: 15px;
            opacity: 0.8;
            margin-left: 12px;
          }
        }
      }

      .we-button {
        margin-bottom: 60px;
      }
    }
  }
}
</style>
