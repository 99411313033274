<i18n>
{
  "en": {
    "update": "Save updates",
    "external-link": "Open form on new tab",
    "delete-title": "Delete form",
    "configs": "Settings",
    "favicon": "Favicon",
    "favicon-description": "Upload an image with a .svg or .png extension and with 96x96 size",
    "logo": "Logo",
    "logo-description": "Upload the logo with a transparent background and preferably in .svg to maintain the quality",
    "configs-input-1": "Background color (hex)",
    "configs-input-2": "Google analytics",
    "configs-input-4": "Stages to save the card",
    "section-1-title": "First step",
    "section-1-input-3": "Terms & Conditions",
    "section-1-input-3-text": "Attach file",
    "section-1-input-4": "Form title",
    "section-1-input-5": "Form description",
    "section-2-title": "Second step",
    "section-2-input-1": "Custom fields",
    "section-2-input-4": "File upload required",
    "section-2-input-5": "File upload description",
    "section-2-input-6": "Only show these products",
    "section-3-title": "Third step",
    "section-3-input-2": "Address required",
    "done-title": "Form completed",
    "done-input-2": "Thank message",
    "done-input-4": "Sender email",
    "done-input-5": "Email title",
    "done-input-6": "Email body",
    "done-input-8": "SMS message",
    "done-input-8-hint": "Only 60 characters are allowed, no accents and no special characters.",
    "not-found-title": "Isn't possible to edit external form",
    "not-found-description": "We notice here that you haven't created any workflows yet. To edit the external form you will need to create a workflow first. How about creating a new one now?",
    "not-found-action": "Click here to create",
    "security-title": "Security",
    "security-input-2": "List of allowed IPs",
    "security-input-3": "List of allowed domains",
    "update-password": "Update password",
    "update-form-error-title": "Wasn't possible to update the web form!",
    "update-form-error-description": "Check if you filled out all required fields correctly",
    "form-request": "Webform requests",
    "form-request-description": "Select the stages that you want to save every form request.",
    "brand": "Brand customization",
    "brand-description": "Attach the logo and make the form looks like your company.",
    "google-analytics-description": "Integrate with GA and get all information of access.",
    "google-analytics-help": "Don't know how to get the code?",
    "watch-tutorial": "Watch tutorial",
    "enable": "Enable",
    "enabled": "Enabled!",
    "ip-filter": "IP Filter",
    "ip-filter-description": "Only access with IP in the list will be allowed to fill the form.",
    "domain-filter": "Domain Filter",
    "domain-filter-description": "Only emails in the domain will be accepted.",
    "password-filter": "Password Filter",
    "password-filter-description": "Only people that know the password will be allowed to fill the form.",
    "user-filter": "User Filter",
    "user-filter-description": "Only registered users will be able to fill out the form.",
    "contact-filter": "Contact Filter",
    "contact-filter-description": "Only registered contacts will be able to fill out the form.",
    "create-first": "Create card on first step",
    "create-first-description": "The system will create the card on the pipeline you selected even he didn't finish all the steps.",
    "terms": "Terms & Conditions",
    "terms-description": "The customer will need to accept the terms before send the form request.",
    "custom-fields": "Custom fields",
    "custom-fields-description": "Select the fields that will need to be filled by the customers.",
    "products": "Products",
    "products-description": "Allow customers to select one or more products and change the quantity for each one.",
    "attachments": "Attachments",
    "attachments-description": "Allow customers to attach one or more files.",
    "address": "Address",
    "address-description": "Allow customers to fill the address",
    "completed": "Congratulations message",
    "completed-description": "Customize the message that customer will see when finish the form.",
    "deal-code": "Card code",
    "deal-code-description": "Allow customer to see the code of card and request help if something happens.",
    "send-email": "Send email",
    "send-email-description": "Customize the email that customer will receive when finish the form.",
    "send-sms": "Send SMS",
    "send-sms-description": "Customize the SMS that customer will receive when finish the form."
  },
  "pt-BR": {
    "update": "Salvar alterações",
    "external-link": "Visualizar formulário",
    "delete-title": "Deletar formulário",
    "configs": "Configurações",
    "favicon": "Favicon",
    "favicon-description": "Faça upload de uma imagem com extensão .svg ou .png e com 96x96 de dimensão",
    "logo": "Logotipo",
    "logo-description": "Faça upload da logomarca com fundo transparente e de preferência em .svg para manter a qualidade",
    "configs-input-1": "Cor de fundo (hexadecimal)",
    "configs-input-2": "Google analytics",
    "configs-input-4": "Estágios para salvar a requisição",
    "section-1-title": "Primeira etapa",
    "section-1-input-3": "Termos & Condições",
    "section-1-input-3-text": "Anexar arquivo",
    "section-1-input-4": "Título do formulário",
    "section-1-input-5": "Descrição do formulário",
    "section-2-title": "Segunda etapa",
    "section-2-input-1": "Campos customizados",
    "section-2-input-4": "Upload de arquivos obrigatório",
    "section-2-input-5": "Descrição do campo de upload de arquivos",
    "section-2-input-6": "Mostrar somente esses produtos",
    "section-3-title": "Terceira etapa",
    "section-3-input-2": "Endereço obrigatório",
    "done-title": "Formulário finalizado",
    "done-input-2": "Mensagem de agradecimento",
    "done-input-4": "E-mail do remetente",
    "done-input-5": "Título do e-mail",
    "done-input-6": "Mensagem do e-mail",
    "done-input-8": "Mensagem do SMS",
    "done-input-8-hint": "Só é permitido 60 caracteres, sem acentuação e sem caracteres especiais.",
    "not-found-title": "Não é possível editar o formulário web",
    "not-found-description": "Nós percebemos aqui que você não criou nenhum pipeline ainda. Para editar o formulário web você vai precisar criar um pipeline primeiro. Que tal criar um agora?",
    "not-found-action": "Clique aqui para criar",
    "security-title": "Segurança",
    "security-input-2": "Lista de IP permitidos",
    "security-input-3": "Lista de domínios permitidos",
    "update-password": "Atualizar senha",
    "update-form-error-title": "Não foi possível atualizar o formulário web!",
    "update-form-error-description": "Verifique se preencheu todos os campos obrigatórios corretamente",
    "form-request": "Requisições do formulário",
    "form-request-description": "Selecione os estágios que você deseja salvar as novas requisições do formulário.",
    "brand": "Personalize com sua marca",
    "brand-description": "Coloque a logo da sua empresa e personalize a apresentação do formulário.",
    "google-analytics-description": "Integre com o Google Analytics e obtenha todas as métricas de acesso ao formulário.",
    "google-analytics-help": "Não sabe como obter o código acima?",
    "watch-tutorial": "Ver tutorial",
    "enable": "Habilitar",
    "enabled": "Habilitado!",
    "ip-filter": "Filtro por IP",
    "ip-filter-description": "Somente IPs da lista poderão acessar o formulário.",
    "domain-filter": "Filtro por domínio",
    "domain-filter-description": "Somente e-mails nos domínios registrados serão aceitos.",
    "password-filter": "Filtro por senha",
    "password-filter-description": "Somente pessoas com a senha poderão acessar o formulário.",
    "user-filter": "Filtro por usuário",
    "user-filter-description": "Somente usuários cadastrados poderão submeter o formulário.",
    "contact-filter": "Filtro por contato",
    "contact-filter-description": "Somente contatos cadastrados poderão submeter o formulário.",
    "create-first": "Criar solicitação na primeira etapa",
    "create-first-description": "O sistema irá criar o card no pipeline mesmo se o usuário não ir até o final do formulário.",
    "terms": "Termos e Condições",
    "terms-description": "Todo mundo que preencher o formulário deverá aceitar os termos e condições.",
    "custom-fields": "Campos customizados",
    "custom-fields-description": "Selecione os campos do sistema que deverão ser preenchidos.",
    "products": "Produtos",
    "products-description": "Será permitido selecionar produtos cadastrados no sistema que serão atrelados ao card do pipeline.",
    "attachments": "Anexar arquivos",
    "attachments-description": "Será permitido anexar arquivos e acessá-los no card que será criado no pipeline.",
    "address": "Endereço",
    "address-description": "Será permitido colocar as informações de endereço na última etapa do formulário.",
    "completed": "Mensagem de agradecimento",
    "completed-description": "Defina a mensagem que o usuário deverá visualizar após completar o formulário.",
    "deal-code": "Código do card",
    "deal-code-description": "O usuário poderá visualizar o código do card para poder solicitar ajuda sobre a requisição.",
    "send-email": "Enviar e-mail",
    "send-email-description": "Personalize o email que o usuário irá receber ao finalizar o formulário.",
    "send-sms": "Enviar SMS",
    "send-sms-description": "Personalize o SMS que o usuário irá receber ao finalizar o formulário."
  }
}
</i18n>

<template>
  <div id="webform-configs">
    <nav-top returnPage="webformsConfigs">
      <template #top-content>
        <span class="domain" @click="openWebform">
          <font-awesome-icon icon="link" />
          /{{ form.domain }}
        </span>
        <we-button
          class="success"
          :text="$t('update')"
          icon="check"
          @click="sendUpdate"
          :loading="loadingButton"
        />
        <we-icon-button
          icon="external-link-alt"
          direction="bottom"
          :name="$t('external-link')"
          @click="openWebform"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          @close="confirmDeleteOpened = false"
          @confirm="deleteWebform"
        />
        <we-icon-button
          class="error--text"
          :icon="['far', 'trash-alt']"
          :name="$t('delete-title')"
          direction="bottom"
          @click="confirmDeleteOpened = true"
          cy="delete-web-form"
        />
      </template>
    </nav-top>
    <board-plugin
      @selectMenu="menuActiveIndex = $event"
      :activeMenu="menuActiveIndex"
      :menu="menu"
    >
      <template #content>
        <form-password
          v-if="formPasswordOpened"
          :formId="$route.params.id"
          @close="formPasswordOpened = false"
        />
        <we-loading-overflow :loading="loading" />
        <we-not-found v-if="!loading && (!pipelines || pipelines.length === 0)">
          <template #title>
            {{ $t("not-found-title") }}
          </template>
          <template #description>
            {{ $t("not-found-description") }}
            <router-link
              :to="{ name: 'pipelinesConfigs' }"
              class="primary--text"
              active-class="active"
            >
              {{ $t("not-found-action") }}
            </router-link>
          </template>
          <template #picture>
            <img
              src="@/assets/undraw/webform.svg"
              alt="Onboarding"
              width="299"
              height="254"
            />
          </template>
        </we-not-found>
        <v-form ref="form" @submit.prevent>
          <section id="form_stages" v-if="menuActiveIndex === 0">
            <we-block
              :title="$t('form-request')"
              :description="$t('form-request-description')"
            >
              <template #sections>
                <div class="block-section flex">
                  <we-input
                    type="global-multiselect"
                    v-model="form.initial_pipeline_stages"
                    :items="allStagesWithPipelinesList"
                    :label="$t('configs-input-4')"
                    is-required
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :title="$t('brand')"
              :description="$t('brand-description')"
            >
              <template #sections>
                <div class="block-section grid">
                  <we-file-upload
                    :title="$t('favicon')"
                    :info="$t('favicon-description')"
                    @change="sendFavicon"
                    :file="form.favicon"
                    :loading="loadingFavicon"
                    accept=".svg,.png"
                  />
                  <we-file-upload
                    :title="$t('logo')"
                    :info="$t('logo-description')"
                    @change="sendLogo"
                    :file="form.brand"
                    :loading="loadingLogo"
                    accept=".pdf,.jpg,.jpeg,.png,.svg"
                  />
                </div>
                <div class="block-section">
                  <we-input
                    type="text"
                    v-model="form.background_color"
                    :label="$t('configs-input-1')"
                    mask="XXXXXX"
                    prefix="#"
                    placeholder="FFFFFF"
                  />
                </div>
                <div class="block-section">
                  <we-input
                    type="text"
                    v-model="form.title"
                    class="double-column"
                    :label="$t('section-1-input-4')"
                  />
                </div>
                <div class="block-section">
                  <we-input
                    type="textarea"
                    v-model="form.description"
                    class="double-column"
                    :label="$t('section-1-input-5')"
                    counter="255"
                    maxlength="255"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              title="Google Analytics"
              :description="$t('google-analytics-description')"
            >
              <template #sections>
                <div class="block-section">
                  <we-input
                    type="text"
                    v-model="form.google_analytics"
                    :label="$t('configs-input-2')"
                    mask="#########-#"
                    prefix="UA-"
                    placeholder="000000000-0"
                  />
                </div>
              </template>
              <template #bottom>
                <div class="bottom-text">
                  {{ $t("google-analytics-help") }}
                </div>
                <div class="bottom-action">
                  <we-button
                    href="https://support.google.com/analytics/answer/9539598"
                    target="_blank"
                    class="primary"
                    :text="$t('watch-tutorial')"
                    icon="play"
                  />
                </div>
              </template>
            </we-block>
          </section>
          <section id="form_security" v-if="menuActiveIndex === 1">
            <we-block
              :active="form.is_ip_filter_enabled"
              class="toggle"
              :title="$t('ip-filter')"
              :description="$t('ip-filter-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_ip_filter_enabled"
                  :label="
                    form.is_ip_filter_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-input
                    type="tags"
                    :label="$t('security-input-2')"
                    :clearable="false"
                    :items="form.ip_list"
                    v-model="form.ip_list"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :active="form.is_password_enabled"
              class="toggle"
              :title="$t('password-filter')"
              :description="$t('password-filter-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_password_enabled"
                  :label="
                    form.is_password_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-button
                    class="disabled fit-content"
                    icon="key"
                    :text="$t('update-password')"
                    @click="formPasswordOpened = true"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :active="form.is_restricted_to_users"
              class="toggle"
              :title="$t('user-filter')"
              :description="$t('user-filter-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_restricted_to_users"
                  :label="
                    form.is_restricted_to_users ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
            </we-block>
            <we-block
              :active="form.is_restricted_to_contacts"
              class="toggle"
              :title="$t('contact-filter')"
              :description="$t('contact-filter-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_restricted_to_contacts"
                  :label="
                    form.is_restricted_to_contacts ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
            </we-block>
            <we-block
              :active="form.is_domain_filter_enabled"
              class="toggle"
              :title="$t('domain-filter')"
              :description="$t('domain-filter-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_domain_filter_enabled"
                  :label="
                    form.is_domain_filter_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-input
                    type="tags"
                    :label="$t('security-input-3')"
                    :clearable="false"
                    :items="form.domain_list"
                    v-model="form.domain_list"
                  />
                </div>
              </template>
            </we-block>
          </section>
          <section id="form_first_step" v-if="menuActiveIndex === 2">
            <we-block
              :active="form.is_lead_mode_enabled"
              class="toggle"
              :title="$t('create-first')"
              :description="$t('create-first-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_lead_mode_enabled"
                  :label="
                    form.is_lead_mode_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
            </we-block>
            <we-block
              :active="form.is_terms_required"
              class="toggle"
              :title="$t('terms')"
              :description="$t('terms-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_terms_required"
                  :label="form.is_terms_required ? $t('enabled') : $t('enable')"
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-file-upload
                    :title="$t('section-1-input-3')"
                    :uploadText="$t('section-1-input-3-text')"
                    @change="sendTerms"
                    :file="form.terms_of_service"
                    :loading="loadingTerms"
                    accept="application/msword, .pdf, text/plain, text/html, image/*"
                  />
                </div>
              </template>
            </we-block>
          </section>
          <section id="form_second_step" v-if="menuActiveIndex === 3">
            <we-block
              :title="$t('custom-fields')"
              :description="$t('custom-fields-description')"
            >
              <template #sections>
                <div class="block-section">
                  <we-input
                    type="custom-fields"
                    v-model="form.custom_fields"
                    :items="dealCustomFields"
                    :clearable="false"
                    :label="$t('section-2-input-1')"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :active="form.is_product_stage_enabled"
              class="toggle"
              :title="$t('products')"
              :description="$t('products-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_product_stage_enabled"
                  :label="
                    form.is_product_stage_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-input-products
                    multiple
                    :label="$t('section-2-input-6')"
                    v-model="selectedProducts"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :active="form.is_file_upload_enabled"
              class="toggle"
              :title="$t('attachments')"
              :description="$t('attachments-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_file_upload_enabled"
                  :label="
                    form.is_file_upload_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <v-switch
                    class="double-column"
                    v-model="form.is_file_upload_required"
                    :label="$t('section-2-input-4')"
                    hide-details
                  />
                </div>
                <div class="block-section">
                  <we-input
                    type="text"
                    v-model="form.file_upload_field_label"
                    class="double-column"
                    :label="$t('section-2-input-5')"
                  />
                </div>
              </template>
            </we-block>
          </section>
          <section id="form_third_step" v-if="menuActiveIndex === 4">
            <we-block
              :active="form.is_address_stage_enabled"
              class="toggle"
              :title="$t('address')"
              :description="$t('address-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_address_stage_enabled"
                  :label="
                    form.is_address_stage_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <v-switch
                    class="double-column"
                    v-model="form.is_address_required"
                    :label="$t('section-3-input-2')"
                    hide-details
                  />
                </div>
              </template>
            </we-block>
          </section>
          <section id="form_completed" v-if="menuActiveIndex === 5">
            <we-block
              :title="$t('completed')"
              :description="$t('completed-description')"
            >
              <template #sections>
                <div class="block-section">
                  <we-editor
                    v-model="form.thank_you_text"
                    class="double-column"
                    :label="$t('done-input-2')"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :active="form.is_code_label_enabled"
              class="toggle"
              :title="$t('deal-code')"
              :description="$t('deal-code-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_code_label_enabled"
                  :label="
                    form.is_code_label_enabled ? $t('enabled') : $t('enable')
                  "
                  hide-details
                />
              </template>
            </we-block>
            <we-block
              :active="form.is_email_enabled"
              class="toggle"
              :title="$t('send-email')"
              :description="$t('send-email-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_email_enabled"
                  :label="form.is_email_enabled ? $t('enabled') : $t('enable')"
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-input
                    v-if="form.is_email_enabled"
                    type="email"
                    v-model="form.email_sender"
                    class="double-column"
                    :label="$t('done-input-4')"
                  />
                </div>
                <div class="block-section">
                  <we-input
                    v-if="form.is_email_enabled"
                    type="text"
                    v-model="form.email_subject"
                    class="double-column"
                    :label="$t('done-input-5')"
                  />
                </div>
                <div class="block-section">
                  <we-editor
                    v-if="form.is_email_enabled"
                    :label="$t('done-input-6')"
                    v-model="form.email_body"
                    class="double-column"
                  />
                </div>
              </template>
            </we-block>
            <we-block
              :active="form.is_sms_enabled"
              class="toggle"
              :title="$t('send-sms')"
              :description="$t('send-sms-description')"
            >
              <template #top>
                <v-switch
                  v-model="form.is_sms_enabled"
                  :label="form.is_sms_enabled ? $t('enabled') : $t('enable')"
                  hide-details
                />
              </template>
              <template #sections>
                <div class="block-section">
                  <we-input
                    v-if="form.is_sms_enabled"
                    type="text"
                    v-model="form.sms_message"
                    class="double-column"
                    :label="$t('done-input-8')"
                    counter="60"
                    maxlength="60"
                    :hint="$t('done-input-8-hint')"
                  />
                </div>
                <div class="block-section">
                  <we-sms-tax
                    v-if="form.is_sms_enabled"
                    class="double-column"
                  />
                </div>
              </template>
            </we-block>
          </section>
        </v-form>
      </template>
    </board-plugin>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormPassword from "@/views/Admin/Configurations/Webforms/FormPassword.vue";
export default {
  components: {
    FormPassword
  },
  data() {
    return {
      menuActiveIndex: 0,
      confirmDeleteOpened: false,
      loading: true,
      loadingButton: false,
      loadingFavicon: false,
      loadingLogo: false,
      loadingTerms: false,
      formPasswordOpened: false,
      selectedProducts: []
    };
  },
  computed: {
    menu() {
      return [
        {
          icon: "cog",
          name: this.$t("configs")
        },
        {
          icon: "key",
          name: this.$t("security-title")
        },
        {
          icon: "user",
          name: this.$t("section-1-title")
        },
        {
          icon: "folder-plus",
          name: this.$t("section-2-title")
        },
        {
          icon: "globe-americas",
          name: this.$t("section-3-title")
        },
        {
          icon: "check",
          name: this.$t("done-title")
        }
      ];
    },
    customer() {
      return this.$store.getters.getCustomer;
    },
    form() {
      return this.$store.getters.getWebform;
    },
    dealCustomFields() {
      return this.$store.getters.getDealCustomFieldsInputs;
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    allStagesWithPipelinesList() {
      let items = [];

      this.pipelines.forEach(pipeline => {
        pipeline?.stages.forEach(stage => {
          items.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id
          });
        });
      });

      return items;
    },
    domain() {
      return this.$store.getters.getDomain;
    }
  },
  methods: {
    ...mapActions([
      "webformRequest",
      "updateWebformRequest",
      "deleteWebformRequest",
      "pipelinesRequest",
      "customFieldsRequest",
      "updateFaviconWebformRequest",
      "updateLogoWebformRequest",
      "updateTermsWebformRequest"
    ]),
    updateSelectedProducts(val) {
      this.selectedProducts = val;
    },
    verifySmsCheckbox(val) {
      if (val) {
        if (this.customer.subscription_plan === "start") {
          setTimeout(() => {
            this.form.is_sms_enabled = false;
          }, 50);
          this.$store.commit("openUpgradePlan");
        }
      }
    },
    verifyEmailCheckbox(val) {
      if (val) {
        if (this.customer.subscription_plan === "start") {
          setTimeout(() => {
            this.form.is_email_enabled = false;
          }, 50);
          this.$store.commit("openUpgradePlan");
        }
      }
    },
    verifyAddressCheckbox(val) {
      if (val) {
        if (this.customer.subscription_plan === "start") {
          setTimeout(() => {
            this.form.is_address_stage_enabled = false;
          }, 50);
          this.$store.commit("openUpgradePlan");
        }
      }
    },
    openWebform() {
      window.open(
        process.env.VUE_APP_WEBFORM_URL + this.domain + "/" + this.form.domain
      );
    },
    async sendFavicon(file) {
      this.loadingFavicon = true;
      file.formId = this.$route.params.id;
      await this.updateFaviconWebformRequest(file);
      this.loadingFavicon = false;
    },
    async sendLogo(file) {
      this.loadingLogo = true;
      file.formId = this.$route.params.id;
      await this.updateLogoWebformRequest(file);
      this.loadingLogo = false;
    },
    async sendTerms(file) {
      this.loadingTerms = true;
      file.formId = this.$route.params.id;
      await this.updateTermsWebformRequest(file);
      this.form.is_terms_required = true;
      this.loadingTerms = false;
    },
    async doRequests() {
      await this.customFieldsRequest({ entity: "deal" });
      await this.webformRequest(this.$route.params.id);
      await this.pipelinesRequest();

      this.loading = false;
    },
    async sendUpdate() {
      this.loadingButton = true;
      const validated = this.$refs.form.validate();

      if (validated) {
        this.form.products = this.selectedProducts.map(e => e.id);
        await this.updateWebformRequest(this.form);
      } else {
        const snackBar = {
          title: this.$t("update-form-error-title"),
          description: this.$t("update-form-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
      }

      this.loadingButton = false;
    },
    async deleteWebform() {
      this.loadingButton = true;
      await this.deleteWebformRequest(this.form);
      this.$router.push({ name: "webformsConfigs" });
      this.loadingButton = false;
    }
  },
  async mounted() {
    await this.doRequests();

    this.selectedProducts = this.form.products;
  }
};
</script>

<style lang="scss">
#webform-configs {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
