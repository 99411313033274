<i18n>
{
  "en": {
    "title": "Change password",
    "input-2": "New password",
    "update": "Update password",
    "rule-2": "At least 6 chars"
  },
  "pt-BR": {
    "title": "Atualizar senha",
    "input-2": "Nova senha",
    "update": "Salvar nova senha",
    "rule-2": "Mínimo 6 caracteres"
  }
}
</i18n>

<template>
  <modal-action @close="closeChangePassword" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon icon="key" />
            </div>
            {{ $t("title") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeChangePassword" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form">
            <div class="fields">
              <we-input
                :label="$t('input-2')"
                type="password"
                is-required
                v-model="password"
              />
            </div>
          </v-form>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="$t('update')"
            :loading="loadingBtn"
            @click="update"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    formId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      loadingBtn: false,
      passwordRules: [
        password => (password && password.length >= 6) || this.$t("rule-2")
      ],
      password: ""
    };
  },
  methods: {
    ...mapActions(["updateWebformPasswordRequest"]),
    closeChangePassword() {
      this.$emit("close");
    },
    async update() {
      this.loadingBtn = true;
      await this.updateWebformPasswordRequest({
        id: this.formId,
        password: this.password
      });
      this.loadingBtn = false;
      this.closeChangePassword();
    }
  }
};
</script>
